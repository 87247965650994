import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["type", "moduleId", "categoriesModuleInstanceWrapper"]
  
    connect() {
        this.galenModules = window.galenModulesData;
        this.changeModuleType()
    }
  
    changeModuleType() {
        this.lookup()
    }

    lookup() {

        const type = this.typeTarget.options[this.typeTarget.selectedIndex]

        let fetchUrlString = null;

        let mod = this.galenModules.filter((module) => module.id === parseInt(type.value) )[0];

        if (mod) {
            if (mod.key === "forms") {
                fetchUrlString = "/form_prototypes.json"
                this.categoriesModuleInstanceWrapperTarget.classList.remove('hidden')
            } else if (mod.key === "pricing_portal") {
                this.categoriesModuleInstanceWrapperTarget.classList.add('hidden')
            } else if (mod.key === "flash_deck") {
                fetchUrlString = "/flash_decks.json"
                this.categoriesModuleInstanceWrapperTarget.classList.remove('hidden')
            } else {
                this.categoriesModuleInstanceWrapperTarget.classList.add('hidden')
            }
    
            if (fetchUrlString) {
                fetch(`${fetchUrlString}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    credentials: 'same-origin'
                })
                .then(response => response.json())
                .then(data => {
    
                    this.moduleIdTarget.innerHTML = '';
    
                    data.forEach((object) => {
                        let option = document.createElement('option');
                        option.value = object.id;
                        option.textContent = object.name;
                        this.moduleIdTarget.appendChild(option);
                    });
    
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    
            }
    
        }


    }

}