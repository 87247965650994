import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["searchText", "content", "custtype", "prodtype"]
  
    connect() {
        this.searchTextTarget.addEventListener('keyup', this.delayedSearch.bind(this));
    }

    delayedSearch(event) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if (this.searchTextTarget.value !== '') {
                this.lookup(event);
            } else {
                this.contentTarget.innerHTML = '';
            }
        }, 300);
    }

    getSelectedEntityType() {
        const selectedRadioButton = document.querySelector('input[name="entity_type"]:checked');
        return selectedRadioButton ? selectedRadioButton.value : null;
    }

    lookup(event) {

        const searchText = this.searchTextTarget.value;
        const entityType = this.getSelectedEntityType();

        fetch(`/pricing_portal/lookup.json?search_text=${searchText}&type=${entityType}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then(data => {

            let htmlContent = '';

            if ( entityType == "customers" ) {
                htmlContent += `<h5>${data.length} Customers</h5>`;
    
                data.forEach(customer => {
                  htmlContent += `
                    <a href="/pricing_portal/${customer.customer_id}/customer_detail">
                    <div class="row">
                      <div class="col p-2">
                        <h5>
                        ${customer.name} (${customer.customer_id})
                        </h5>
                        <p>
                        Distributor Name: ${customer.distributor_name} (${customer.distributor_identifier})
                        <br />
                        Distributor Location: ${customer.distributor_location}
                        <p>
                      </div>
                    </div>
                    </a>
                    `;
                    
                });
    
            } else {
                htmlContent += `<h5>${data.length} Products</h5>`;
    
                data.forEach(product => {
                  htmlContent += `
                    <a href="/pricing_portal/${product.catalog_number}/product_detail">
                    <div class="row">
                      <div class="col p-2">
                        <h5>
                        ${product.product_description} (${product.catalog_number})
                        </h5>
                        <p>
                            <strong>System:</strong> ${product.system} <strong>Type:</strong> ${product.product_type}
                        <p>
                      </div>
                    </div>
                    </a>
                    `;
                    
                });
    
            }

        
            this.contentTarget.innerHTML = htmlContent;
                        
        })
        .catch(error => {
            console.error('Error:', error);
        });


    }

}